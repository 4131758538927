import styled from '@emotion/styled';
import { buildStyles, CircularProgressbarWithChildren, CircularProgressbar, } from 'react-circular-progressbar';
import { flex, } from '~/modules/AppLayout/FlexGridCss';
import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { css } from '@emotion/react';
export const StockUpAndDownCountCirculProgressbar = memo(function StockUpAndDownCountCirculProgressbar(props) {
    const { isPhone, isPc } = useMedia();
    // const color = props.value > 0 ? '#ff3333' : props.value < 0 ? '#33ff33' : '#00000000'
    const StockUpAndDownCount = props.StockUpAndDownCount;
    const riseCountPercent = StockUpAndDownCount[0].percent;
    const flatCountPercent = StockUpAndDownCount[1].percent;
    const circleRatio = 0.75;
    const strokeWidth = 14;
    const rotation = 1 / 2 + 1 / 8;
    return (<CirculProgressbarStyled.Container>
      <CirculProgressbarStyled.ProgressbarContent isPhone={!isPc}>
        <CircularProgressbarWithChildren value={riseCountPercent + flatCountPercent} circleRatio={circleRatio} strokeWidth={strokeWidth} styles={buildStyles({
            rotation,
            pathColor: '#eeeeee',
            trailColor: '#33ff33',
            strokeLinecap: 'butt',
        })}>
          <CircularProgressbar value={riseCountPercent} circleRatio={circleRatio} strokeWidth={strokeWidth} text='TSEA加權指數' styles={buildStyles({
            rotation,
            strokeLinecap: 'butt',
            textColor: '#eeeeee',
            textSize: 10,
            pathColor: '#ff3333',
            trailColor: 'transparent',
        })}/>
        </CircularProgressbarWithChildren>
      </CirculProgressbarStyled.ProgressbarContent>
    </CirculProgressbarStyled.Container>);
});
export const CirculProgressbarStyled = {
    Container: styled.div `
    ${flex.v.crossCenter}
    /* width: 100%;
    height: 100%; */
    /* background-color: #222734; */
    /* background-color: red; */
    border-radius: 10px;
    /* padding: 4px; */
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      background-color: #333845;
    }
  `,
    ProgressbarContent: styled.div `
    /* width: 30%; */
    width: 150px;
    /* height: 50%; */
    /* height: 100px; */
    /* ${props => props.isPhone &&
        css `
        width: 65px;
      `} */
  `,
};
