import { memo } from 'react';
import { css } from '@emotion/react';
import { fill_horizontal_all_center, fill_vertical_cross_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { useStockPriceChangeDistributionState } from '~/pages/futuresai/stock-price-change-distribution/components/useStockPriceChangeDistributionState';
import { useSnapshot } from 'valtio';
import styled from '@emotion/styled';
import { sungopStockStore } from '~/pages/sungop_stock/_private/Component/sungopStock_store';
import AboveAvgLine from '~/modules/investment-consultant/aboveAvgLine/StockAboveMaChart';
import { StockUpAndDownCount } from '~/pages/sungop_stock/_private/Component/MarketViewCard/StockUpAndDownCount';
export const MarketTrendSwitch = memo(function MarketTrendSwitch() {
    const state = useSnapshot(sungopStockStore);
    return (<div css={css `
        width: 100%;
        height: 100%;
      `}>
      <styleds.PageMenuSymbolList>
        <div css={css `
            ${fill_horizontal_all_center};
            height: 30px;
            gap: 4px;
          `}>
          <styleds.PageMenuButton active={state.marketViewMode === 'AboveAvgLine'} onClick={() => (sungopStockStore.marketViewMode = 'AboveAvgLine')}>
            市場趨勢
          </styleds.PageMenuButton>
          <styleds.PageMenuButton active={state.marketViewMode === 'StockUpAndDownCount'} onClick={() => (sungopStockStore.marketViewMode = 'StockUpAndDownCount')}>
            當日漲跌家數
          </styleds.PageMenuButton>
        </div>
      </styleds.PageMenuSymbolList>

      <styleds.StockAboveMaChart>
        {state.marketViewMode === 'AboveAvgLine' && (<AboveAvgLine.display market1='上市櫃' market2='上市櫃' ma1={20} ma2={60} days={60}/>)}
        {state.marketViewMode === 'StockUpAndDownCount' && (<useStockPriceChangeDistributionState.Provider>
            <StockUpAndDownCount />
          </useStockPriceChangeDistributionState.Provider>)}
      </styleds.StockAboveMaChart>
    </div>);
});
const styleds = {
    PageMenuButton: styled.div `
    ${fill_vertical_all_center};
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #2d303e;
    border: 1px solid ${options => (options.active === true ? '#aaaaaa' : '#2e303daa')};
    cursor: pointer;
    &:hover {
      background-color: #3e414e;
    }
  `,
    PageMenuSymbolList: styled.div `
    ${fill_vertical_cross_center}
    justify-content: start;
    padding: 4px;
    gap: 8px;
    /* height: calc(100% - 312px); */
    height: 30px;
  `,
    StockAboveMaChart: styled.div `
    background-color: #2e323b;
    border-radius: 6px;
    height: calc(100% - 30px);
  `,
};
