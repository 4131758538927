import { css } from '@emotion/react';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, flex, } from '~/modules/AppLayout/FlexGridCss';
import { WatchListWithChart } from '~/modules/symbolQuote/simple';
import { EntrySymbolList } from '~/pages/sungop_stock/_private/Component/EntrySymbolList';
import { FirebaseSymbolList } from '~/pages/sungop_stock/_private/Component/FirebaseSymbolList';
import { sungopStockStore } from '~/pages/sungop_stock/_private/Component/sungopStock_store';
import IntradayTrendChart from '~/modules/trendChart2';
import styled from '@emotion/styled';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { store } from '~/pages/heineken_template/_private/store';
// import { sungopStock_customized2 } from '~/trades/indicators/sungop_stock/sungopStock_customized2'
import { sungopStock_customized1 } from '~/trades/indicators/sungop_stock/sungopStock_customized1';
import { sungopStock_customized3 } from '~/trades/indicators/sungop_stock/sungopStock_customized3';
import { sungopStock_customized0 } from '~/trades/indicators/sungop_stock/sungopStock_customized0';
import { WatchListAddButton } from '~/pages/edwin/component/WatchListAddButton';
const indicators = [
    sungopStock_customized0,
    sungopStock_customized1,
    // sungopStock_customized2,
    sungopStock_customized3,
];
export const SungopStock_SidePane1 = memo(function SungopStock_SidePane1(props) {
    const state = useSnapshot(sungopStockStore);
    const charting = useSnapshot(store).charting;
    return (<styleds.SideBar>
      <styleds.symbol.titleQuote>
        <TitleSymbolQuote.Simple symbol={charting.symbol}/>
        <WatchListAddButton groupName={'sungop_stock_group_1'}/>
      </styleds.symbol.titleQuote>
      <styleds.symbol.trendChart>
        <IntradayTrendChart symbol={charting.symbol} ticksSize={12} ticksHeight={20} priceTicksMargin={-14} priceTicksSize={12}/>
      </styleds.symbol.trendChart>
      <styleds.PageMenuSymbolList>
        <div css={css `
            ${fill_horizontal_all_center};
            height: 30px;
            gap: 4px;
          `}>
          <styleds.PageMenuButton active={state.menuSymbolMode === 'entrySignal'} onClick={() => (sungopStockStore.menuSymbolMode = 'entrySignal')}>
            近期訊號
          </styleds.PageMenuButton>
          <styleds.PageMenuButton active={state.menuSymbolMode === 'firebase'} onClick={() => (sungopStockStore.menuSymbolMode = 'firebase')}>
            AI精選
          </styleds.PageMenuButton>
          <styleds.PageMenuButton active={state.menuSymbolMode === 'user'} onClick={() => (sungopStockStore.menuSymbolMode = 'user')}>
            自選股
          </styleds.PageMenuButton>
        </div>
      </styleds.PageMenuSymbolList>

      <div css={css `
          ${fill_vertical_cross_center};
          height: calc(100% - 250px);
        `}>
        {state.menuSymbolMode === 'entrySignal' && <EntrySymbolList />}
        {state.menuSymbolMode === 'firebase' && <FirebaseSymbolList />}
        {state.menuSymbolMode === 'user' && (<WatchListWithChart groupName={'sungop_stock_group_1'} useChart={store.charting} indicators={indicators}/>)}
      </div>
    </styleds.SideBar>);
});
const styleds = {
    SideBar: styled.div `
    ${flex.v.crossCenter};
    width: 100%;
    height: 100%;
    /* gap: 4px; */
    padding: 4px;
  `,
    // container: styled.div`
    //   ${fill_vertical_cross_center};
    //   padding: 4px;
    //   gap: 4px;
    //   border-radius: 6px;
    //   background-color: #1d2630;
    // `,
    symbol: {
        titleQuote: styled.div `
      ${fill_horizontal_cross_center};
      display: grid;
      grid-template-columns: calc(100% - 72px) 72px;
      gap: 8px;
      width: 100%;
      height: 28px;
    `,
        trendChart: styled.div `
      width: 100%;
      height: 200px;
      /* background-color: #272c35; */
      background-color: #2e323b;
      border-radius: 4px;
      padding: 4px;
    `,
    },
    PageMenuButton: styled.div `
    ${fill_vertical_all_center};
    border-radius: 4px;
    background-color: #2d303e;
    border: 1px solid ${options => (options.active === true ? '#aaaaaa' : '#2e303daa')};
    cursor: pointer;
    &:hover {
      background-color: #3e414e;
    }
  `,
    PageMenuSymbolList: styled.div `
    ${fill_vertical_cross_center}
    justify-content: start;
    padding: 4px;
    gap: 8px;
    /* height: calc(100% - 312px); */
    height: 30px;
  `,
};
