import styled from '@emotion/styled';
import { memo, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useDailyRankResource } from '~/modules/screener/containers/useStockScreenerResource';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import KbarSymbolQuote from '../quote/KbarSymbolQuote';
import { edwinGroupName } from '../WatchListGroupName';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
import LoadCard from '~/modules/symbolPlatform/LoadCard';
export const HighPriceStockContent = memo(function HighPriceStockContent() {
    const intraDay = useSnapshot(staticStore).tradedDate.intraday;
    const getHighPriceSymbol = useDailyRankResource({
        date: intraDay.format('YYYY-MM-DD'),
        volume_gte: 0,
        volume_lte: 0,
        amount_gte: 0,
        amount_lte: 0,
        order_by: 'desc',
        sort_by: 'high',
    }, 0).data?.map(s => s.symbol) ?? [''];
    const highPriceSymbol = getHighPriceSymbol.slice(0, 18);
    useEffect(() => {
        signalrStore2.addQuote(highPriceSymbol);
        return () => {
            signalrStore2.removeQuote(highPriceSymbol);
        };
    }, [JSON.stringify(highPriceSymbol)]);
    const highPriceValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(highPriceSymbol, 2));
    const loadComplete = highPriceValue?.length >= highPriceSymbol.length;
    const watchList = useSymbolWatchList(edwinGroupName);
    return (<classes.container>
      <classes.header>
        <KbarSymbolQuote.header sortGroup={2}/>
      </classes.header>
      <classes.body>
        {!loadComplete && <LoadCard.Loading />}
        {loadComplete &&
            highPriceValue?.map((quote, index) => {
                return (<KbarSymbolQuote.body symbolWatchList={watchList} key={index} quote={quote}/>);
            })}
      </classes.body>
    </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
  `,
    header: styled.div `
    width: 100%;
    height: 40px;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
  `,
};
