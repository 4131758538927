import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_cross_center, flex, } from '~/modules/AppLayout/FlexGridCss';
import IntradayTrendChart from '~/modules/trendChart2';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { MarketTrendSwitch } from '~/pages/sungop_stock/_private/Component/MarketViewCard/SungopStockMarketTrendSwitch';
import { horizontalScrollbarCss } from '~/css/scrollbarCss';
import useMedia from '~/hooks/useMedia';
export const SungopStockMarketViewCard = memo(function StockUpAndDownCountCard() {
    //const charting = useSnapshot(store).charting
    const { isPhone, isPc } = useMedia();
    return (<styleds.container isPhone={!isPc}>
      <styleds.content.container isPhone={!isPc}>
        <styleds.content.item isPhone={!isPc}>
          <styleds.content.trendChart>
            <TitleSymbolQuote.Default symbol={'TSEA'}/>
            <IntradayTrendChart symbol={'TSEA'} ticksSize={10} ticksHeight={14} priceTicksMargin={-12} priceTicksSize={12}/>
          </styleds.content.trendChart>
        </styleds.content.item>
        <styleds.content.item isPhone={!isPc}>
          <styleds.content.trendChart>
            <TitleSymbolQuote.Default symbol={'OTCA'}/>
            <IntradayTrendChart symbol={'OTCA'} ticksSize={10} ticksHeight={14} priceTicksMargin={-20} priceTicksSize={12}/>
          </styleds.content.trendChart>
        </styleds.content.item>
        <styleds.content.MarketTrendSwitch isPhone={!isPc}>
          <MarketTrendSwitch />
        </styleds.content.MarketTrendSwitch>
      </styleds.content.container>
    </styleds.container>);
});
export const styleds = {
    container: styled.div `
    ${fill_horizontal_cross_center};
    background-color: #1d2630;
    border-radius: 6px;
    height: 100%;
    width: 100%;
  `,
    content: {
        container: styled.div `
      ${fill_horizontal_cross_center}
      ${horizontalScrollbarCss}
    `,
        item: styled.div `
      ${flex.h.allCenter};
      width: 300px;
      height: calc(100% - 8px);
      flex-shrink: 0;
      padding: 2px;
    `,
        trendChart: styled.div `
      display: grid;
      grid-template-rows: 26px calc(100% - 32px);
      background-color: #2e323b;
      border-radius: 6px;
      height: 100%;
      width: 100%;
    `,
        MarketTrendSwitch: styled.div `
      background-color: #2e323b;
      border-radius: 6px;
      min-width: 400px;
      width: calc(100% - 615px);
      height: calc(100% - 12px);
      padding: 2px;
    `,
    },
};
