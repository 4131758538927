import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { Button } from '@mantine/core';
import { edwin_store } from './edwin_store';
import { useSnapshot } from 'valtio';
import { ValueStocksContent } from './component/content/ValueStocksContent';
import { IndustryInfo } from './component/content/IndustryInfoContent';
import { IndustryBar } from './component/content/IndustryBarContent';
import { HighPriceStockContent } from './component/content/HighPriceStockContent';
import { OsFuturesContent } from './component/content/OsFuturesContent';
const ModeButton = memo(function ModeButton(props) {
    const state = useSnapshot(edwin_store).mode2;
    return (<Button variant='gradient' size='sm' gradient={state === props.mode
            ? { from: '#ecb04d', to: '#f4bd50', deg: 90 }
            : { from: '#404550', to: '#404550', deg: 90 }} css={css `
          color: ${state === props.mode ? '#252525' : ''};
          width: 100%;
          height: 28px;
          padding: 0;
        `} onClick={() => (edwin_store.mode2 = props.mode)}>
        {props.children}
      </Button>);
});
export const Edwin_Sidebar2 = memo(function Edwin_Sidebar2() {
    //要顯示: 相關產業 / 熱門產業
    const state = useSnapshot(edwin_store);
    return (<styleds.container>
      <styleds.buttonGroup>
        <Button.Group css={css `
            width: 100%;
            gap: 2px;
          `}>
          <ModeButton mode='1'>基本</ModeButton>
          <ModeButton mode='2'>權值股</ModeButton>
          <ModeButton mode='3'>高價股</ModeButton>
          <ModeButton mode='4'>產業概況</ModeButton>
          <ModeButton mode='5'>海期</ModeButton>
        </Button.Group>
      </styleds.buttonGroup>

      <div css={css `
          ${fill_vertical_cross_center};
          height: calc(100% - 48px);
        `}>
        {state.mode2 === '1' && <IndustryInfo />}
        {state.mode2 === '2' && <ValueStocksContent />}
        {state.mode2 === '3' && <HighPriceStockContent />}
        {state.mode2 === '4' && <IndustryBar />}
        {state.mode2 === '5' && <OsFuturesContent />}
      </div>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 4px;
    border-radius: 6px;
    background-color: #1d2630;
  `,
    buttonGroup: styled.div `
    ${fill_horizontal_cross_center};
    height: 40px;
    gap: 8px;
    font-size: 14px;
    border-bottom: 1px solid #343434;
  `,
};
