import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { Charting } from '~/pages/heineken_template/_private/Charting';
import { store } from '~/pages/heineken_template/_private/store';
import { sungopStock_initStrategies } from '~/pages/sungop_stock/_private/sungopStock_initStrategies';
import { useSignalrStart2_1 } from '~/modules/SDK/Signalr/useSignalrStart2_1';
import dayAPI from '~/utils/dayAPI';
import { SungopStockMarketViewCard } from '~/pages/sungop_stock/_private/Component/MarketViewCard/SungopStockMarketViewCard';
import { SungopStock_Topbar } from '~/pages/sungop_stock/_private/SungopStock_Topbar';
import { SungopStock_SidePane1 } from '~/pages/sungop_stock/_private/SungopStock_SidePane1';
import Fr_WithDrawerCloseButton from '~/pages/heineken_template/_fr/fr_WithDrawerCloseButton';
import { BsChevronLeft } from 'react-icons/bs';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { sungopStock_initStyling } from './sungopStock_initStyling';
export const sungopStock_init = (templateProps) => {
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
    sungopStock_initStrategies();
    sungopStock_initStyling(templateProps);
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode('dark');
    templateProps.layout.Charting = Charting;
    templateProps.layout.Row1 = SungopStock_Topbar;
    templateProps.layout.Row2 = SungopStockMarketViewCard;
    store.charting.calcFrom = dayAPI().subtract(5, 'day');
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 200px 1fr;
    grid-template-columns: 300px calc(100% - 300px);

    grid-template-areas:
      'Row1 Row1'
      'Drawer1 Row2'
      'Drawer1 Chart';

    overflow: auto;
    ${createIPadCss(css `
      place-content: flex-start;
      grid-template-rows: 48px 200px calc(100% - 248px);
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Row2 Row2 Row2'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css `
      place-content: flex-start;
      grid-template-rows: 48px 200px max(calc(100% - 248px), 500px);
      grid-template-columns: 100%;
      grid-template-areas:
        'Row1'
        'Row2'
        'Chart';
    `)}
  `;
    templateProps.layout.Drawer1 = (<Fr_WithDrawerCloseButton.Display right Button={<BsChevronLeft css={css `
            #__html {
              position: relative;
              z-index: 100;
            }
            color: #ffffff;
            background-color: #42948844;
            border-radius: 5px;
            height: 64px;
            width: 28px;
            font-size: 24px;
          `}/>}>
      <SungopStock_SidePane1 />
    </Fr_WithDrawerCloseButton.Display>);
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TSEA',
        interval: '1D',
    };
    templateProps.hooks.add(useSignalrStart2_1);
};
