import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@mantine/core';
import { memo } from 'react';
import { fill_horizontal_cross_center, } from '~/modules/AppLayout/FlexGridCss';
/** google標單填寫預約開戶服務 */
//https://docs.google.com/forms/d/e/1FAIpQLSelCwbjv2wpsGd7G0I6IouLwW3sOmezek6ttLAXjit-H9P7xw/viewform
export const FromOpenAccount = memo(function FromOpenAccount(props) {
    return (<classes.container css={props.containerCss}>
      <Button css={css `
          padding: 2px;
          /* color: #ffffff; */
          gap: 4px;
          border-radius: 5px;

          cursor: pointer;
          /* background-color: yellow; */
          background-color: rgb(76, 86, 95);
          /* background-image: linear-gradient(to right, #8f41e9, #578aef); */
          line-height: 18px;
          transition: 0.3s;
          border: 1px solid #cccccc;
          &:hover {
            /* background-image: linear-gradient(to left, #cf41e9, #f78aef); */
            /* text-decoration: underline; */
            background-color: #578aef;
          }
        `}>
        <a href={props.customURL} target={'_blank'} rel='noreferrer' css={css `
            color: #ffffff;
          `}>
          便利下單功能申請
        </a>
      </Button>
    </classes.container>);
});
const classes = {
    container: styled.div `
    border-radius: 4px;
    ${fill_horizontal_cross_center};
    height: 40px;
    width: 100%;
    /* padding: 20px;
    height: 30px; */
    /* margin: 2px; */
    /* border-bottom: 1px solid #cccccc; */
  `,
};
// const openAccounURL =
//   'https://docs.google.com/forms/d/e/1FAIpQLSelCwbjv2wpsGd7G0I6IouLwW3sOmezek6ttLAXjit-H9P7xw/viewform'
// export const FromOpenAccountMantineButton = memo<ReactProps>(
//   function FromOpenAccountMantineButton() {
//     return (
//       <Button
//         css={css`
//           width: 136px;
//           height: 40px;
//           font-size: 13px;
//           padding-right: 0;
//           padding-left: 0;
//         `}
//         color='gray'
//         variant='gradient'
//         gradient={{ from: 'red', to: 'yellow' }}
//         onClick={() => window.open(openAccounURL, '_blank')}
//       >
//         開戶服務預約表單
//       </Button>
//     )
//   },
// )
