import { Progress } from '@mantine/core';
import { memo, useEffect } from 'react';
import { css } from '@emotion/react';
import { fill_horizontal_cross_center, jc, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { useStockPriceChangeDistributionState } from '~/pages/futuresai/stock-price-change-distribution/components/useStockPriceChangeDistributionState';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { useMount } from 'react-use';
import useMedia from '~/hooks/useMedia';
import { StockUpAndDownCountCirculProgressbar } from '~/pages/sungop_stock/_private/Component/MarketViewCard/StockUpAndDownCountCirculProgressbar';
const MarketItem = memo(function MarketItem(props) {
    const data = props.data;
    const { isPhone, isPc } = useMedia();
    // if (!isPc)
    //   return (
    //     <div
    //       css={css`
    //         width: calc(100% - 4px);
    //         height: 33%;
    //         display: grid;
    //         grid-template-columns: 40% 30% 30%;
    //         font-size: 12px;
    //         /* padding: 1px 4px; */
    //       `}
    //     >
    //       <div>{data.label}</div>
    //       <span
    //         css={css`
    //           text-align: center;
    //         `}
    //       >
    //         {data.count}
    //       </span>
    //       <span
    //         css={css`
    //           color: ${data.color};
    //           text-align: center;
    //         `}
    //       >
    //         {data.percent.toFixed(1)}%
    //       </span>
    //     </div>
    //   )
    return (<div css={css `
        width: calc(100% - 4px);
        /* height: 30%; */
        display: grid;
        grid-template-rows: 18px 18px 8px;
        font-size: 12px;
        padding: 1px 4px;
        z-index: 999;
      `}>
      <div>{data.label}</div>
      <div css={css `
          ${fill_horizontal_cross_center};
          align-items: end;
          ${jc.spaceBetween};
        `}>
        <span>{data.count}</span>
        <span css={css `
            font-size: 12px;
            color: ${data.color};
          `}>
          {data.percent.toFixed(1)}%
        </span>
      </div>
      <div>
        <Progress css={css `
            margin-top: 4px;
          `} value={100} color={data.color} size={2} radius={'xs'}/>
      </div>
    </div>);
});
export const StockUpAndDownCount = memo(function StockUpAndDownCount() {
    const { state: { overallCount, fallCount, flatCount, riseCount, allCategories }, acts: { setCategories }, } = useStockPriceChangeDistributionState.useContainer();
    const market = 'TSEA';
    const marketChinese = '上市';
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    useMount(() => {
        setCategories([marketChinese]);
    });
    const data = [
        {
            label: '上漲家數',
            count: riseCount,
            percent: (riseCount / overallCount) * 100,
            color: '#ff0031',
        },
        {
            label: '平盤家數',
            count: flatCount,
            percent: (flatCount / overallCount) * 100,
            color: '#acacac',
        },
        {
            label: '下跌家數',
            count: fallCount,
            percent: (fallCount / overallCount) * 100,
            color: '#00ff31',
        },
    ];
    //單一呼叫signalr
    useEffect(() => {
        signalrStore2.addQuote(market);
        return () => {
            signalrStore2.removeQuote(market);
        };
    }, [market]);
    return (<div css={css `
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100%;
        height: 100%;
        padding: 4px;
        /* background-color: #2d323c; */
        background-color: #2e323b;
        border-radius: 6px;
        font-size: 14px;
        cursor: pointer;
        border: 1px solid #3a3f4b;
        /* &:hover {
          background-color: #3a3f4b;
        } */
      `}>
      <StockUpAndDownCountCirculProgressbar StockUpAndDownCount={data}/>

      <div css={css `
          ${fill_vertical_all_center}
          width: 100%;
          /* height: calc(100% - 8px); */
          /* height: 100%; */
          /* gap: 4px; */
          border-radius: 4px;

          /* padding: 4px; */
        `}>
        {data.map((item, index) => {
            return (<MarketItem key={index} data={item}/>);
        })}
      </div>
    </div>);
});
