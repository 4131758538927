export const dailyAmplitudeStatsCalculator = (data, numDays) => {
    const dailyAmplitudes = [];
    const dataHigh = data.h.slice(0, -1);
    const dataLow = data.l.slice(0, -1);
    const dataData = data.t.slice(0, -1);
    for (let i = dataData.length; i >= Math.max(dataData.length - numDays, 0); i--) {
        const amplitude = dataHigh[i] - dataLow[i];
        dailyAmplitudes.unshift(amplitude);
    }
    dailyAmplitudes.pop();
    const sum = dailyAmplitudes.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const average = sum / dailyAmplitudes.length;
    const max = Math.max(...dailyAmplitudes);
    const min = Math.min(...dailyAmplitudes);
    //console.log('max:', max, 'min:', min, 'all:', dailyAmplitudes)
    const belowAverageAmplitudes = dailyAmplitudes.filter(amplitude => amplitude < average);
    const aboveAverageAmplitudes = dailyAmplitudes.filter(amplitude => amplitude > average);
    const belowAverage = belowAverageAmplitudes.length > 0
        ? belowAverageAmplitudes.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / belowAverageAmplitudes.length
        : 0;
    const aboveAverage = aboveAverageAmplitudes.length > 0
        ? aboveAverageAmplitudes.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / aboveAverageAmplitudes.length
        : 0;
    return {
        average,
        max,
        min,
        belowAverage,
        aboveAverage,
    };
};
